<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <vue-topprogress ref="topProgress" />

        <b-col>
          <filter-panel
            ref="filterPanel"
            @search-triggered="getData"
            :show-cities="true"
            :show-regularity="true"
            :show-date-range="false"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="">
          <clip-loader
            v-if="isLoading"
            class="ml-3 mt-2"
            :size="14"
            color="#36D7B7"
            :loading="isLoading"
          />
          <v-client-table
            v-if="!isLoading"
            ref="table"
            :data="table.data"
            :columns="table.columns"
            :options="table.options"
            @row-click="onRowClick"
          >
            <span slot="Invoice count" slot-scope="props">{{
              parseInt(props.row["Invoice count"])
            }}</span>
            <span slot="Average days between invoices" slot-scope="props">{{
              parseFloat(props.row["Average days between invoices"]).toFixed()
            }}</span>
            <span slot="Average invoice total $" slot-scope="props"
              >${{
                parseFloat(props.row["Average invoice total $"]).toLocaleString(
                  "en-US"
                )
              }}</span
            >
          </v-client-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { vueTopprogress } from "vue-top-progress";
import FilterPanel from "@/components/FilterPanelSimple";

export default {
  name: "OrderRegularity",
  components: {
    "vue-topprogress": vueTopprogress,
    "filter-panel": FilterPanel
  },
  data: function() {
    return {
      isLoading: false,
      table: {
        data: [],
        columns: [],
        options: {
          //filterByColumn: true,
          filterable: ["Account Name"],
          texts: {
            filter: "Filter by account name:"
          },
          customSorting: {
            "Invoice count": function(ascending) {
              return function(a, b) {
                return this.$helpers.compareFloat(
                  a["Invoice count"],
                  b["Invoice count"],
                  ascending
                );
              };
            },
            "Average days between invoices": function(ascending) {
              return function(a, b) {
                return this.$helpers.compareFloat(
                  a["Average days between invoices"],
                  b["Average days between invoices"],
                  ascending
                );
              };
            },
            "Average invoice total $": function(ascending) {
              return function(a, b) {
                return this.$helpers.compareFloat(
                  a["Average invoice total $"],
                  b["Average invoice total $"],
                  ascending
                );
              };
            }
          },
          perPage: 50
        }
      }
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onRowClick: function(event) {
      /*
      this.$store.dispatch(
        "filterPanel.selected.accountname",
        event.row["Account Name"]
      );
      */

      this.$form.prepareDrillDownFilters(
        this.$refs.filterPanel,
        event.row["Account Name"]
      );

      //open new page
      this.$router.push({ name: "Account overview" });
    },

    getData: function() {
      //this.$refs.filterPanel.loading = true;

      let filter = this.$refs.filterPanel.selected;

      this.drawTable(filter);
    },
    drawTable: function(filter) {
      this.isLoading = true;

      this.$api.data.getMarketOrderRegularity(filter).then(response => {
        this.isLoading = false;
        let o = response.find(o => o !== {});
        this.table.columns = Object.getOwnPropertyNames(o);
        this.table.data = response;
      });
    }
  }
};
</script>

<style></style>
