var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', [_c('vue-topprogress', {
    ref: "topProgress"
  }), _c('b-col', [_c('filter-panel', {
    ref: "filterPanel",
    attrs: {
      "show-cities": true,
      "show-regularity": true,
      "show-date-range": false
    },
    on: {
      "search-triggered": _vm.getData
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {}, [_vm.isLoading ? _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": _vm.isLoading
    }
  }) : _vm._e(), !_vm.isLoading ? _c('v-client-table', {
    ref: "table",
    attrs: {
      "data": _vm.table.data,
      "columns": _vm.table.columns,
      "options": _vm.table.options
    },
    on: {
      "row-click": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "Invoice count",
      fn: function fn(props) {
        return _c('span', {}, [_vm._v(_vm._s(parseInt(props.row["Invoice count"])))]);
      }
    }, {
      key: "Average days between invoices",
      fn: function fn(props) {
        return _c('span', {}, [_vm._v(_vm._s(parseFloat(props.row["Average days between invoices"]).toFixed()))]);
      }
    }, {
      key: "Average invoice total $",
      fn: function fn(props) {
        return _c('span', {}, [_vm._v("$" + _vm._s(parseFloat(props.row["Average invoice total $"]).toLocaleString("en-US")))]);
      }
    }], null, false, 2896454538)
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }