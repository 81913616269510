<template>
  <div>
    <b-row class="p-3" align-h="center">
      <b-col cols="12" lg="10">
        <b-row>
          <b-col sm="3" lg="3" class="p-1" v-if="showTerritories">
            <multiselect
              v-model="selected.territory"
              track-by="name"
              label="name"
              :loading="loading"
              :options="territories"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select territory"
            />
          </b-col>

          <b-col sm="3" lg="3" class="p-1" v-if="showCountries">
            <multiselect
              v-model="selected.country"
              track-by="name"
              label="name"
              :loading="loading"
              :options="countries"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select country"
            />
          </b-col>

          <b-col sm="3" lg="2" class="p-1" v-if="showStates">
            <multiselect
              v-model="selected.state"
              :loading="loading"
              :options="states"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select market"
            />
          </b-col>

          <b-col sm="3" lg="2" class="p-1" v-if="showCities">
            <multiselect
              v-model="selected.city"
              :loading="loading"
              :options="cities"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select city"
            />
          </b-col>

          <b-col
            sm="3"
            :lg="visibleItems === 1 ? 10 : 3"
            class="p-1"
            v-if="showProductCategories"
          >
            <multiselect
              v-model="selected.productcategory"
              :loading="loading"
              :options="productcategories"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select product category"
            />
          </b-col>
          <b-col sm="3" lg="2" class="p-1" v-if="showAccounts">
            <multiselect
              ref="accounts"
              v-model="selected.accountname"
              :loading="accounts.loading"
              :options="accounts.data"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select account"
            />
          </b-col>

          <b-col sm="3" lg="2" class="p-1" v-if="showChains">
            <multiselect
              ref="chain"
              v-model="selected.chain"
              :loading="chains.loading"
              :options="chains.data"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select chain"
            />
          </b-col>

          <b-col sm="3" lg="2" class="p-1" v-if="showUsers">
            <multiselect
              ref="users"
              v-model="selected.user"
              track-by="id"
              label="fullname"
              :loading="users.loading"
              :options="users.data"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select user"
            />
          </b-col>
          <b-col sm="3" lg="2" class="p-1" v-if="showRegularity">
            <multiselect
              ref="regularity"
              v-model="selected.regularity"
              :options="regularity"
              :multiple="false"
              deselect-label=""
              select-label=""
              placeholder="Select regularity"
            />
          </b-col>
          <b-col
            sm="3"
            :lg="visibleItems === 1 ? 12 : 3"
            class="p-1"
            v-if="showDateRange"
          >
            <date-range-picker
              ref="picker"
              :opens="dateRange.options.opens"
              :locale-data="dateRange.options.locale"
              :single-date-picker="dateRange.options.singleDatePicker"
              :auto-apply="dateRange.options.autoApply"
              :show-dropdowns="true"
              v-model="selected.period"
              :linked-calendars="dateRange.options.linkedCalendars"
              :ranges="dateRange.options.ranges"
              @update="dateRange.update"
            >
              <masked-input
                mask="1111-11-11 - 1111-11-11"
                placeholder="yyyy-mm-dd - yyyy-mm-dd"
                ref="picker-input"
                slot="input"
                class="form-control picker-input"
                :value="dateRange.input.value"
                @input="onInput"
              />
            </date-range-picker>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="12"
        lg="2"
        class="text-center"
        style="padding-top:6px; max-width:150px;"
      >
        <b-button block @click="searchData" variant="outline-secondary">
          <font-awesome-icon icon="sync" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="pr-5" align-h="end">
      <span style="color:#D3D3D3; font-size: 10px;">FPS 1.0</span>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import MaskedInput from "vue-masked-input";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const _ranges = {
  "This week": [moment().startOf("week"), moment().endOf("week")],
  "This month": [moment().startOf("month"), moment().endOf("month")],
  "Last month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .subtract(1, "month")
      .endOf("month")
  ],
  "This quarter": [
    moment()
      .quarter(moment().quarter())
      .startOf("quarter"),
    moment()
      .quarter(moment().quarter())
      .endOf("quarter")
  ],
  "Last quarter": [
    moment()
      .subtract(1, "quarter")
      .startOf("quarter"),
    moment()
      .subtract(1, "quarter")
      .endOf("quarter")
  ],
  "Year to date": [moment().startOf("year"), moment()],
  "Last 12 months": [moment().subtract(12, "month"), moment()],
  "Last calendar year": [
    moment()
      .subtract(1, "year")
      .startOf("year"),
    moment()
      .subtract(1, "year")
      .endOf("year")
  ]
};

export default {
  components: {
    Multiselect,
    DateRangePicker,
    MaskedInput
  },
  props: {
    showTerritories: {
      type: Boolean,
      default: true
    },
    showCountries: {
      type: Boolean,
      default: true
    },
    showStates: {
      type: Boolean,
      default: true
    },
    showCities: {
      type: Boolean,
      default: false
    },
    showProductCategories: {
      type: Boolean,
      default: false
    },
    showAccounts: {
      type: Boolean,
      default: false
    },
    showChains: {
      type: Boolean,
      default: false
    },
    showUsers: {
      type: Boolean,
      default: false
    },
    showRegularity: {
      type: Boolean,
      default: false
    },
    showDateRange: {
      type: Boolean,
      default: true
    },
    defaultRange: {
      type: String,
      default: "Last quarter"
    }
  },
  data: function() {
    return {
      loading: false,
      filterReset: false,
      territories: [],
      districts: [],
      countries: [],
      states: [],
      cities: [],
      productcategories: [],
      accounts: {
        data: [],
        loading: false
      },
      chains: {
        data: [],
        loading: false
      },
      users: {
        data: [],
        loading: false
      },
      regularity: [
        "1 - On track",
        "2 - Expected Soon",
        "3 - Overdue",
        "4 - Inactive"
      ],
      dateRange: {
        value: {},
        options: {
          opens: "right",
          singleDatePicker: false,
          autoApply: true,
          linkedCalendars: true,
          ranges: _ranges,
          locale: {
            direction: "ltr", //direction of text
            format: "DD-MM-YYYY", //format of the dates displayed
            separator: " - ", //separator between the two ranges
            applyLabel: "Apply",
            cancelLabel: "Cancel",
            weekLabel: "W",
            customRangeLabel: "Custom Range",
            daysOfWeek: moment.weekdaysMin(), //array of days - see moment documentations for details
            monthNames: moment.monthsShort(), //array of month names - see moment documentations for details
            firstDay: 1 //ISO first day of week - see moment documentations for details
          }
        },
        update: value => {
          value.startDate = moment(value.startDate).format("YYYY-MM-DD");
          value.endDate = moment(value.endDate).format("YYYY-MM-DD");

          this.dateRange.input.value = `${this.selected.period.startDate} - ${this.selected.period.endDate}`;
        },
        input: {
          value: ""
        }
      },
      selected: {
        territory: {},
        country: {},
        state: "",
        city: "",
        productcategory: "",
        accountname: "",
        chain: "",
        user: {
          id: null,
          name: null,
          fullname: null
        },
        regularity: "",
        period: {
          startDate: this.showDateRange
            ? this.getRange(this.defaultRange).startDate
            : null,
          endDate: this.showDateRange
            ? this.getRange(this.defaultRange).endDate
            : null
        }
      }
    };
  },
  computed: {
    visibleItems() {
      let cnt =
        this.showTerritories +
        this.showCountries +
        this.showStates +
        this.showCities +
        this.showProductCategories +
        this.showAccounts +
        this.showChains +
        this.showUsers +
        this.showRegularity +
        this.showDateRange;

      return cnt;
    }
  },
  mounted: function() {
    // this.selected.country = { name: 'United States', iso_code: 'US', }
    if (this.showTerritories) {
      this.$api.get("dictionaries/territories").then(response => {
        this.territories = response.map(u => ({
          id: u.ID,
          name: u.Name
        }));
      });
    }

    if (this.showCountries || this.showStates) {
      this.$api.countries.getMapped().then(response => {
        this.districts = response;

        let m = response.map(item => ({
          name: item.country,
          iso_code: item.iso_code
        }));

        m.forEach(item => {
          if (!this.countries.find(c => c.name === item.name))
            this.countries.push(item);
        });

        this.updateStates();
        this.updateCities();
      });
    }
    if (this.showProductCategories) {
      this.$api.productcategories.get().then(response => {
        this.productcategories = [
          ...new Set(response.map(item => item.productcategory))
        ];
      });
    }

    if (this.showAccounts) {
      this.accounts.loading = true;

      this.$api.accounts.get().then(response => {
        this.accounts.loading = false;

        this.accounts.data = [
          ...new Set(response.map(item => item.accountname))
        ];
      });
    }

    if (this.showChains) {
      this.chains.loading = true;

      this.$api.chains.get().then(response => {
        this.chains.loading = false;

        this.chains.data = [...new Set(response.map(item => item.name))];
      });
    }
    if (this.showUsers) {
      this.users.loading = true;

      this.$api.get("dictionaries/users").then(response => {
        this.users.loading = false;

        this.users.data = [
          ...new Set(
            response.map(item => ({
              fullname: item.Name,
              name: item.user_name,
              id: item.id
            }))
          )
        ];
      });
    }
    //debugger
    if (this.$store.getters["filterPanel.selected.period"])
      this.selected.period = {
        ...this.$store.getters["filterPanel.selected.period"]
      };
    //debugger
    /*
        if (this.$store.getters['filterPanel.selected.period2']) this.selected.period2 = {
            ...this.$store.getters['filterPanel.selected.period2']
        }
        if (this.$store.getters['filterPanel.selected.period3']) this.selected.period3 = {
            ...this.$store.getters['filterPanel.selected.period3']
        }*/

    this.selected.country =
      this.$store.getters["filterPanel.selected.countries"][0] ||
      this.selected.country;
    this.selected.state =
      this.$store.getters["filterPanel.selected.states"][0] ||
      this.selected.state;
    this.selected.city =
      this.$store.getters["filterPanel.selected.cities"][0] ||
      this.selected.city;
    this.selected.accountname =
      this.$store.getters["filterPanel.selected.accountname"] ||
      this.selected.accountname;
    this.selected.chain =
      this.$store.getters["filterPanel.selected.chain"] || this.selected.chain;
    this.selected.user =
      this.$store.getters["filterPanel.selected.user"] || this.selected.user;

    this.dateRange.input.value = `${this.selected.period.startDate} - ${this.selected.period.endDate}`;
  },

  methods: {
    onInput: function(value, value2) {
      if (value2.replace("_", "").length !== 16) return;

      let period = this.stringToPeriod(value);
      this.selected.period = period;
      this.dateRange.value = period;
    },
    stringToPeriod(str) {
      let p = str.split(" - ");

      if (p.length === 2) {
        let startDate = moment(p[0].trim());
        let endDate = moment(p[1].trim());

        if (startDate.isValid() && endDate.isValid())
          return {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
          };
      }

      return {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      };
    },
    getRange(value) {
      let range = {};

      let val = _ranges[value];

      if (val) {
        range = {
          startDate: val[0].format("YYYY-MM-DD"),
          endDate: val[1].format("YYYY-MM-DD")
        };
      }

      return range;

      /*
                        if (value === 'This quarter') {
                            range.start = moment().startOf('quarter')
                            range.end = moment().endOf('quarter')
                        }

                        if (value === 'Last quarter') {
                            range.start = moment().subtract(1, 'quarter').startOf('quarter')
                            range.end = moment().subtract(1, 'quarter').endOf('quarter')
                        }

                        if (value === 'This year') {
                            range.start = moment().startOf('year')
                            range.end = moment().endOf('month')
                        }

                        if (value === 'Last 5 years') {
                            range.start = moment().year(moment().subtract(5, 'year').year()).startOf('year')
                            range.end = moment()
                        }

                        return {
                            start: moment(range.start).format('YYYY-MM-DD'),
                            end: moment(range.end).format('YYYY-MM-DD'),
                        }
                        */
    },
    searchData: function() {
      this.$store.dispatch("filterPanel.selected.territories", [
        this.selected.territory
      ]);
      this.$store.dispatch("filterPanel.selected.countries", [
        this.selected.country
      ]);
      this.$store.dispatch("filterPanel.selected.states", [
        this.selected.state
      ]);
      this.$store.dispatch("filterPanel.selected.cities", [this.selected.city]);
      this.$store.dispatch(
        "filterPanel.selected.accountname",
        this.selected.accountname
      );
      this.$store.dispatch("filterPanel.selected.chain", this.selected.chain);
      this.$store.dispatch("filterPanel.selected.user", this.selected.user);
      this.$store.dispatch("filterPanel.selected.period", {
        ...this.selected.period
      });
      //this.$store.dispatch('filterPanel.selected.period2', {...this.selected.period2})
      //this.$store.dispatch('filterPanel.selected.period3', {...this.selected.period3})

      this.$emit("search-triggered", null);
    },

    updateStates: function() {
      if (!this.districts) return;
      let restricted_districts = this.districts.filter(item =>
        this.selected.country !== null
          ? this.selected.country.name === item.country
          : false
      );

      //update list of states
      this.states = [...new Set(restricted_districts.map(v => v.state))];
    },

    updateCities: function() {
      if (!this.districts) return;
      let restricted_districts = this.districts.filter(
        item => this.selected.state === item.state
      );

      this.cities = [...new Set(restricted_districts.map(v => v.city))];
    },
    setPeriodValue(_value) {
      this.selected.period = _value;

      this.dateRange.update(_value);
    }
  },
  watch: {
    "selected.country": function(newVal, oldVal) {
      this.updateStates();

      if (newVal.name !== oldVal.name && !!oldVal.name && !!newVal.name) {
        this.selected.state = "";
      }
    },
    "selected.state": function(newVal, oldVal) {
      if (newVal === null) this.selected.state = "";

      this.updateCities();

      if (newVal !== oldVal && !!oldVal) {
        this.selected.city = "";
      }
    },

    "selected.city": function(newVal) {
      if (newVal === null) this.selected.city = "";
    },

    "selected.productcategory": function(newVal) {
      this.selected.productcategory = newVal || "";
    },
    "selected.accountname": function(newVal) {
      this.selected.accountname = newVal || "";
    },
    "selected.chain": function(newVal) {
      this.selected.chain = newVal || "";
    }
  }
};
</script>

<style>
.reportrange-text {
  min-height: 40px;
  width: 230px !important;
  cursor: pointer;
  display: block;

  border-radius: 5px;
  border: 1px solid #e8e8e8 !important;
  background: #fff;
  font-size: 14px;
}

.vue-daterange-picker {
  position: relative;
  display: block !important;
}

.picker-input {
  margin: -4px;
  border-width: 0px;
  border: none;
  outline: none;
}

input:active,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}
</style>
